import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className='not-found'>
      <h1 className='not-found not-found-text'>We are sorry!</h1>
      <h1 className='not-found not-found-404'>404</h1>
      <p className='not-found not-found-text'>The page you requested was not found</p>
    </div>
  </Layout>
)

export default NotFoundPage
